
import { defineComponent } from 'vue';
import { store } from '@/store/store';
import { RESOURCE_TEMPLATE, RESOURCE_SECTION, resourceSaveName, resourceDisplayName } from '@/utils.resources';
import { ACTION } from '@/store/actions';
import ConfirmAction from '@/components/ConfirmAction.vue';
import { STATE } from '@/constants';

const GDRIVE_ICON =
  `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87.3 78">`
+ `    <path d="m6.6 66.85 3.85 6.65c.8 1.4 1.95 2.5 3.3 3.3l13.75-23.8h-27.5c0 1.55.4 3.1 1.2 4.5z" fill="#0066da"/>`
+ `    <path d="m43.65 25-13.75-23.8c-1.35.8-2.5 1.9-3.3 3.3l-25.4 44a9.06 9.06 0 0 0 -1.2 4.5h27.5z" fill="#00ac47"/>`
+ `    <path d="m73.55 76.8c1.35-.8 2.5-1.9 3.3-3.3l1.6-2.75 7.65-13.25c.8-1.4 1.2-2.95 1.2-4.5h-27.502l5.852 11.5z" fill="#ea4335"/>`
+ `    <path d="m43.65 25 13.75-23.8c-1.35-.8-2.9-1.2-4.5-1.2h-18.5c-1.6 0-3.15.45-4.5 1.2z" fill="#00832d"/>`
+ `    <path d="m59.8 53h-32.3l-13.75 23.8c1.35.8 2.9 1.2 4.5 1.2h50.8c1.6 0 3.15-.45 4.5-1.2z" fill="#2684fc"/>`
+ `    <path d="m73.4 26.5-12.7-22c-.8-1.4-1.95-2.5-3.3-3.3l-13.75 23.8 16.15 28h27.45c0-1.55-.4-3.1-1.2-4.5z" fill="#ffba00"/>`
+ `</svg>`;

export default defineComponent({
    name: 'Resource',
    components: { ConfirmAction },
    data() {
        return {
            originalTitle: '',
            originalResource: '',
            resource: '',
            title: '',
            docEditMode: false,
            gDriveIcon: GDRIVE_ICON,
            showLinkMenu: false,
            menuItemShown: '',
            linkName: '',
            linkID: '',
            linkPreviewID: '',
            linkURL: '',
            caretPos: null as null | {node: Node, offset: number, coords: [number, number], section: HTMLDivElement}
        }
    },
    props: {
        resourceName: {
            type: String,
            required: true
        }
    },
    computed: {
        prettyTitle(): string {
            return resourceDisplayName(this.title);
        },
        canEdit(): boolean {
            return store.getters.isAdmin && this.validResource;
        },
        validResource(): boolean {
            return this.resource !== '' && !this.resourceLoading;
        },
        resourceLoading(): boolean {
            return (
                !store.state.resourceRequestStatus[this.resourceName] ||
                store.state.resourceRequestStatus[this.resourceName].status === STATE.LOADING
            );
        }
    },
    watch: {
        canEdit() {
            this.processRawResourceHTML();
        },
        resourceName() {
            this.initContent();
        },
        docEditMode() {
            const sections = document.getElementsByClassName('section');
            for(let i = 0; i < sections.length; i++){
                const section = sections.item(i) as HTMLDivElement;
                (Array.from(section.getElementsByClassName('title--text') ?? []) as HTMLSpanElement[]).shift()?.setAttribute('contenteditable', this.docEditMode ? 'true': 'false');
                (Array.from(section.getElementsByClassName('content') ?? []) as HTMLDivElement[]).shift()?.setAttribute('contenteditable', this.docEditMode ? 'true': 'false');
            }
        }
    },
    mounted() {
        this.initContent();
    },
    methods: {
        async initContent() {
            const res = await store.dispatch(ACTION.FETCH_RESOURCE, this.resourceName);
            this.resource = res;
            this.originalResource = res;

            // install events on <i> elements
            this.$nextTick(() => {
                this.updateResource();
                this.processRawResourceHTML();
                this.installEvents();
            });
        },

        resetTitle() {
            if(!this.canEdit) return;
            this.extractTitle();
        },
        toggleDocumentEditMode(toggle?: boolean) {
            if(!this.canEdit) return;
            this.docEditMode = toggle ?? !this.docEditMode;
            this.toggleRemoveIcons(this.docEditMode);
        },

        toggleRemoveIcons(toggle: boolean) {
            const container = (this.$refs.resource as HTMLElement)?.firstElementChild as HTMLDivElement;
            if(!container) return;

            Array.from(container.getElementsByClassName('icon-trash') ?? []).forEach(e => {
                e.classList.toggle('hidden', !toggle);
            });
        },

        extractTitle() {
            const title = ((this.$refs.resource as HTMLElement)?.firstElementChild as HTMLDivElement).getAttribute('data-title');
            this.title = resourceSaveName((!title || title === ''? this.resourceName: title));
            this.originalTitle = this.title;

            const titleEl = (this.$refs['resource-title'] as unknown as HTMLSpanElement);
            if(!titleEl) return;

            titleEl.innerText = this.prettyTitle;
        },

        processRawResourceHTML() {
            const container = (this.$refs.resource as HTMLElement)?.firstElementChild as HTMLDivElement;
            if(!container) return;

            this.postProcessHTML(container);

            this.addTitleButtonBar();
            this.extractTitle();
        },

        addTitleButtonBar(pSections?: HTMLDivElement[]) {
            const container = (this.$refs.resource as HTMLElement)?.firstElementChild as HTMLDivElement;
            if(!container) return;

            const sections = pSections ?? Array.from(container.getElementsByClassName('section') ?? []) as HTMLDivElement[];
            sections.forEach((section,ix) => {
                const header = (Array.from(section.getElementsByClassName('title') ?? []) as HTMLElement[]).shift();

                const buttonBar = document.createElement('div');
                // determine if body is collapsed to init with proper class
                buttonBar.className = 'title__buttons';
                buttonBar.setAttribute('contenteditable', 'false');
                header?.appendChild(buttonBar);
            });

            this.addCollapseIcons(pSections);
            this.addRemoveIcons(pSections);
        },

        saveResource() {
            if(!this.canEdit) return;
            const container = (this.$refs.resource as HTMLElement)?.firstElementChild?.cloneNode(true) as HTMLDivElement;
            if(!container) return;

            const newTitle = resourceSaveName((this.$refs['resource-title'] as unknown as HTMLSpanElement).textContent ?? this.resourceName);
            const oldTitle = this.title;
            this.title = newTitle;
            container.setAttribute('data-title', newTitle);

            this.postProcessHTML(container);
            if(oldTitle !== newTitle && oldTitle !== RESOURCE_TEMPLATE) {
                store.dispatch(ACTION.EDIT_RESOURCE, {resource: oldTitle, title: newTitle, content: container.outerHTML});
            }
            else {
                store.dispatch(ACTION.SET_RESOURCE, {title: newTitle, content: container.outerHTML});
            }
        },

        saveAndQuitEditMode(){
            this.saveResource();
            this.toggleDocumentEditMode(false);
        },

        resetChanges() {
            const container = (this.$refs.resource as HTMLElement)?.firstElementChild as HTMLDivElement;
            if(!container) return;
            container.innerHTML = this.originalResource;

            this.$nextTick(() => {
                this.updateResource();
                this.processRawResourceHTML();
                this.toggleDocumentEditMode(false);
            });
        },

        // Add all the update code  for moving old html tags and classes to the new ones required
        // by the events and css in this VUE template
        updateResource() {
            const container = (this.$refs.resource as HTMLElement)?.firstElementChild as HTMLDivElement;
            if(!container) return;

            const sections = Array.from(container.getElementsByClassName('section') ?? []) as HTMLDivElement[];
            sections.forEach((section,ix) => {
                if(!Array.from(section.getElementsByClassName('title--text')).length) {
                    const titleText = document.createElement('span');
                    titleText.className = 'title--text';

                    const header = Array.from(section.getElementsByClassName('title') ?? [] as HTMLHeadingElement[]).shift();
                    if(!header) return false;

                    titleText.innerHTML = header.innerHTML;
                    header.innerHTML = '';

                    Array.from(section.getElementsByClassName('title') ?? [] as HTMLHeadingElement[]).shift()?.appendChild(titleText);
                }
            });
        },

        removeGeneralResource() {
            if(!this.canEdit) return;
            store.dispatch(ACTION.REMOVE_RESOURCE, this.title);
        },
        postProcessHTML(container: HTMLDivElement) {
            // remove stuff added dynamically
            if(!container) return; // the next tick in mounted will take care of that

            const sections = Array.from(container.getElementsByClassName('section') ?? []) as HTMLDivElement[];
            const headerButtons = Array.from(container.getElementsByClassName('title__buttons') ?? []) as HTMLElement[];
            sections.forEach((section,ix) => {
                section.removeAttribute('contenteditable');
                (Array.from(section.getElementsByClassName('title--text') ?? []) as HTMLSpanElement[]).shift()?.removeAttribute('contenteditable');
                (Array.from(section.getElementsByClassName('content') ?? []) as HTMLDivElement[]).shift()?.removeAttribute('contenteditable');
                const headerButtonBar = headerButtons[ix];
                headerButtonBar?.remove();
            });
        },
        installEvents() {
            //Add collapse listner on icons
            const body = document.getElementsByClassName('page__body').item(0);
            if(!body) throw 'Cannot install events on the page';

            body.addEventListener('click', ev => {
                const target = ev.target as HTMLElement | null;
                if(!target) return;

                const section = target.closest('.section') as HTMLDivElement | null;
                if(!section) return;

                if(target.getAttribute('trigger') === 'collapse') this.toggleSection(section);
                else if(target.getAttribute('trigger') === 'remove') this.removeSection(section);

                this.showLinkMenu = false;

                ev.stopPropagation;
                return false;
            });

            const registerCaretPos = () => {
                const sel = window.getSelection();
                if(!sel || !sel.anchorNode) return null;

                const section = sel.anchorNode.parentElement?.closest('.content') as HTMLDivElement | null;
                if(!section) {
                    this.caretPos = null;
                    return;
                }

                let {x, y} = sel.getRangeAt(0).getBoundingClientRect();
                if(x === 0 && y === 0) {
                    const startCtnr = (sel.getRangeAt(0).startContainer as HTMLElement)?.getBoundingClientRect() ?? {x: 0, y: 0};

                    x = startCtnr.x;
                    y = startCtnr.y;
                }

                this.caretPos = { node : sel.anchorNode, coords: [x, y], offset: sel.anchorOffset, section };
            }

            const mouseupEv = () => {
                registerCaretPos()
            };
            const keyupEv = () => {
                registerCaretPos();
            };

            body.addEventListener('focusin', ev => {
                const target = ev.target as HTMLElement | null;
                if(!target) return;

                const section = target.classList.contains('content') ? target as HTMLDivElement: null;
                if(!section) {
                    this.caretPos = null;
                    return;
                }

                section.removeEventListener('keyup', keyupEv);
                section.removeEventListener('mouseup', mouseupEv);
                section.addEventListener('keyup', keyupEv);
                section.addEventListener('mouseup', mouseupEv);

                ev.stopPropagation;
                return false;
            });
        },
        toggleSection(section: HTMLDivElement) {
            const body = section.getElementsByClassName('content').item(0) as HTMLDivElement | null;
            const collapseIcon = section.getElementsByClassName('icon-angle-down').item(0) || section.getElementsByClassName('icon-angle-right').item(0);

            if(!body || !collapseIcon) return;

            let style = body.style.display;
            style = style === 'none'? 'block': 'none';
            body.style.display = style;
            collapseIcon.className = style === 'none'?
                'icon-angle-right':
                'icon-angle-down';
        },
        removeSection(section: HTMLDivElement) {
            section.remove();
            this.saveResource();
        },
        addCollapseIcons(pSections?: HTMLDivElement[]) {
            // add collapse triggers
            const container = (this.$refs.resource as HTMLElement)?.firstElementChild as HTMLDivElement;
            if(!container) return; // the next tick in mounted will take care of that

            const sections = pSections ?? Array.from(container.getElementsByClassName('section') ?? []) as HTMLDivElement[];
            sections.forEach((section,ix) => {
                const headerButtonBar = (Array.from(section.getElementsByClassName('title__buttons') ?? []) as HTMLElement[]).shift();
                const body = (Array.from(section.getElementsByClassName('content') ?? []) as HTMLDivElement[]).shift();

                const collapseIcon = document.createElement('i');
                collapseIcon.setAttribute('trigger', 'collapse');
                // determine if body is collapsed to init with proper class
                collapseIcon.className = body?.style?.display === 'none'? 'icon-angle-right': 'icon-angle-down';
                headerButtonBar?.appendChild(collapseIcon);
            });
        },
        addRemoveIcons(pSections?: HTMLDivElement[]) {
            // add remove edit triggers based on user priviledges
            const container = (this.$refs.resource as HTMLElement)?.firstElementChild as HTMLDivElement;
            if(!this.canEdit || !container) return; // the next tick in mounted will take care of that

            // Sections ares defined by h3 dom tags.
            const sections = pSections ?? Array.from(container.getElementsByClassName('section') ?? []) as HTMLDivElement[];
            sections.forEach(section => {
                const headerButtonBar = (Array.from(section.getElementsByClassName('title__buttons') ?? []) as HTMLElement[]).shift();

                const removeIcon = document.createElement('i');
                removeIcon.className = 'icon-trash hidden';
                removeIcon.setAttribute('trigger', 'remove');
                removeIcon.setAttribute('title', 'Enlever la section')
                headerButtonBar?.appendChild(removeIcon);
            });
        },
        addNewSection() {
            const container = (this.$refs.resource as HTMLElement)?.firstElementChild as HTMLDivElement;
            if(!container) return;

            container.insertAdjacentHTML('beforeend', RESOURCE_SECTION);

            const newSection = container.lastElementChild as HTMLDivElement;

            if (!newSection) throw 'Could not create new Section';

            this.addTitleButtonBar([newSection]);
        },

        insertLink() {
            this.showLinkMenu = true;
            this.menuItemShown = 'link';
        },
        insertPreview() {
            this.showLinkMenu = true;
            this.menuItemShown = 'preview';
        },
        insertImage() {
            this.showLinkMenu = true;
            this.menuItemShown = 'image';
        },

        handleCreateLinkApply() {
            if(this.menuItemShown === 'image') return this.createImage();
            else if(this.menuItemShown === 'link') return this.createLink();
            else if(this.menuItemShown === 'preview') return this.createPreview();
            else throw `Cannot handle such action: ${this.menuItemShown}`;
        },

        createPreview() {
            if(!this.linkName) this.linkName = this.linkPreviewID ?? this.linkURL;
            if(!this.caretPos) {
                this.linkPreviewID = '';
                this.linkName = '';
                this.showLinkMenu = false;
                return;
            }

            if(this.linkPreviewID) {
                const iframe = document.createElement('iframe');
                iframe.setAttribute('src', `https://docs.google.com/file/d/${this.linkPreviewID}/preview`);
                iframe.setAttribute('title', this.linkName);
                iframe.classList.add('user__preview');

                const content = this.caretPos.node.textContent ?? ''

                this.caretPos.node.textContent = content?.slice(0, this.caretPos?.offset);
                this.caretPos.node.parentNode?.appendChild(iframe);
                this.caretPos.node.parentNode?.appendChild(document.createTextNode(content?.slice(this.caretPos.offset)))
            }

            this.linkPreviewID = '';
            this.linkName = '';
            this.showLinkMenu = false;
        },

        createImage() {
            if(!this.linkName) this.linkName = this.linkID;
            if(!this.caretPos) {
                this.linkID = '';
                this.linkName = '';
                this.showLinkMenu = false;
                return;
            }

            if(this.linkID) {
                const img = document.createElement('img');
                img.setAttribute('src', `https://drive.google.com/uc?id=${this.linkID}`);
                img.setAttribute('alt', this.linkName);
                img.classList.add('user__img');

                const content = this.caretPos.node.textContent ?? ''

                this.caretPos.node.textContent = content?.slice(0, this.caretPos?.offset);
                this.caretPos.node.parentNode?.appendChild(img);
                this.caretPos.node.parentNode?.appendChild(document.createTextNode(content?.slice(this.caretPos.offset)))
            }

            this.linkID = '';
            this.linkName = '';
            this.showLinkMenu = false;
        },

        createLink() {
            if(!this.linkName) this.linkName = this.linkURL;
            if(!this.caretPos) {
                this.linkName = '';
                this.linkURL = '';
                this.showLinkMenu = false;
                return;
            }

            if(this.linkURL) {
                const anchor = document.createElement('a');
                anchor.setAttribute('href', this.linkURL);
                anchor.textContent = this.linkName;

                const content = this.caretPos.node.textContent ?? ''

                this.caretPos.node.textContent = content?.slice(0, this.caretPos?.offset);
                this.caretPos.node.parentNode?.appendChild(anchor);
                this.caretPos.node.parentNode?.appendChild(document.createTextNode(content?.slice(this.caretPos.offset)))
            }

            this.linkName = '';
            this.linkURL = '';
            this.showLinkMenu = false;
        }
    }
});
